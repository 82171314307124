.dashboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px; /* Espace entre les éléments */
    max-width: 100%; 
}
.device-manager {
    display: flex;
    min-width: 600px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 5px rgba(0,0,0,0.1);
}