.time-range-manager {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.time-range-form {
 margin-bottom: 20px;
}

.time-range-form label {
    margin-right: 10px;
}

input[type="time"] {
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    margin-left:5px;
}

button.btn {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    background-color: #007BFF;
    margin-left: 5px;
}

button.btn.add {
    background-color: #28a745;
}

button.btn.delete {
    background-color: #007bff;
    margin-right: 5px;
}

button.btn.reset {
    background-color: #6c757d;
}

button.btn.deploy {
    background-color: #dc3545;
}

button.btn.deploy.deployed {
    background-color: #28a745;  /* Vert pour indiquer le succès */
}

.time-range-list {
    list-style: none;
    padding: 0;
}

.time-range-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-top: 10px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 4px;
    border-color: #28a745;
    border-width: 1px;
    font-size: 20px;
}

.time-range-list li + li {
    margin-top: 5px;
}

.error-message {
    color: #dc3545; /* Rouge d'erreur */
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 10px;
    margin: 0 0 10px 0;
    border-radius: 4px;
    border: 1px solid transparent;
}
