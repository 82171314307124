h1 {
  margin:0;
  margin-top: 30px;
}
h2 {
  margin:0;
  margin-bottom: 10px;
}
.toggle-button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s;
  }
  
  .toggle-button.on {
    background-color: #28a745; /* Vert pour ON */
  }
  
  .toggle-button.off {
    background-color: #dc3545; /* Rouge pour OFF */
  }
  
  .toggle-button:focus {
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.5);
  }

  .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #ccc;
    display: inline-block;
    margin-left: 10px;
  }

  .green {
    background-color: #28a745;
  }
  .red {
    background-color: #dc3545;
  }